import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Photoswipe from 'react-photoswipe-gallery';

import 'photoswipe/dist/photoswipe.css';

/**
 * Props:
 *
 * images: array of images provided using format: { thumb: gatsbyImageData , full: gatsbyImageData }
 * render: string. Allowed values: columns, twe, photoswipe. Default: columns.
 * displayThumbs: boolean. If true then the embedded galleries will use the thumb images.
 */
export default function Gallery({ render, images, displayThumbs }) {
  if (render === 'twe') {
    return <GalleryTWE images={images} displayThumbs={displayThumbs} />;
  } else if (render === 'photoswipe') {
    return <GalleryPhotoswipe images={images} />;
  }

  return <GalleryColumns images={images} displayThumbs={displayThumbs} />;
}

export function GalleryColumns({ images = [], displayThumbs = false }) {
  return (
    <div className="gap-6 space-y-6 columns-2 md:columns-3">
      {images.map(image => (
        <GatsbyImage
          image={displayThumbs ? image.thumb : image.full}
          className="block object-cover object-center w-full h-full rounded-lg"
        />
      ))}
    </div>
  );
}

export function GalleryTWE({ images = [], displayThumbs = false }) {
  return (
    <div className="flex flex-wrap -m-1 md:-m-2">
      {images.map(image => (
        <div className="flex flex-wrap w-1/2 md:w-1/3">
          <div className="w-full p-1 md:p-2">
            <GatsbyImage
              image={displayThumbs ? image.thumb : image.full}
              className="block object-cover object-center w-full h-full rounded-lg"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export function GalleryPhotoswipe({ images }) {
  return (
    <div className="gap-6 space-y-6 columns-2 md:columns-3">
      <Photoswipe.Gallery>
        {images.map(image => {
          const full = image.full ? image.full : image;
          const thumb = image.thumb ? image.thumb : full;
          return (
            <Photoswipe.Item
              original={thumb.images.fallback.src}
              originalSrcset={full.images.fallback.srcSet}
              thumbnail={full.images.fallback.src}
              width={full.width}
              height={full.height}
            >
              {({ ref, open }) => {
                const handleKeyDown = event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    open();
                  }
                };
                return (
                  <div
                    ref={ref}
                    onClick={open}
                    onKeyDown={handleKeyDown}
                    role="button"
                    tabIndex="0"
                    className="cursor-pointer group"
                  >
                    <GatsbyImage
                      image={thumb}
                      className="block object-cover object-center w-full h-full transition-transform duration-300 ease-in-out rounded-lg hover:scale-105 group-focus:scale-105"
                    />
                  </div>
                );
              }}
            </Photoswipe.Item>
          );
        })}
      </Photoswipe.Gallery>
    </div>
  );
}
