import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import Gallery from '../components/gallery';

export default function GalleryPage() {
  const data = useStaticQuery(graphql`
    query {
      kitchens: allFile(
        filter: { relativeDirectory: { eq: "gallery/kitchens" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 400)
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }

      bathrooms: allFile(
        filter: { relativeDirectory: { eq: "gallery/bathrooms" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 400)
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }

      outdoor: allFile(
        filter: { relativeDirectory: { eq: "gallery/outdoor" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 400)
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }

      carpentry: allFile(
        filter: { relativeDirectory: { eq: "gallery/carpentry" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 400)
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  const imagesKitchens = data.kitchens.nodes.map(node => node.childImageSharp);
  const imagesBathrooms = data.bathrooms.nodes.map(node => node.childImageSharp);
  const imagesOutdoor = data.outdoor.nodes.map(node => node.childImageSharp);
  const imagesCarpentry = data.carpentry.nodes.map(node => node.childImageSharp);

  return (
    <>
      <Helmet>
        <title>Photo Gallery | Two Thumbs Up</title>
      </Helmet>
      <Layout isFull={true}>
        <div id="kitchens" className="flex justify-center mx-auto prose">
          <h1>Kitchens</h1>
        </div>
        <div className="py-10">
          <Gallery images={imagesKitchens} render="photoswipe" />
        </div>

        <div id="bathrooms" className="flex justify-center pt-10 mx-auto prose">
          <h1>Bathrooms</h1>
        </div>
        <div className="py-10">
          <Gallery images={imagesBathrooms} render="photoswipe" />
        </div>

        <div id="outdoor" className="flex justify-center pt-10 mx-auto prose">
          <h1>Outdoor Living</h1>
        </div>
        <div className="py-10">
          <Gallery images={imagesOutdoor} render="photoswipe" />
        </div>

        <div id="carpentry" className="flex justify-center pt-10 mx-auto prose">
          <h1>Carpentry</h1>
        </div>
        <div className="py-10">
          <Gallery images={imagesCarpentry} render="photoswipe" />
        </div>
      </Layout>
    </>
  );
}
